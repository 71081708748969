<template>
  <v-container class="px-3" style="height: 100vh; background-color: white" fluid>
    <p class="pt-3 pb-3" style="color: #532E88; font-weight: bolder">
      <v-icon color="primary" class="pl-7 pr-2">fas fa-comment</v-icon
      > Atendimentos</p>
    <v-card >
      <v-row class="pt-2 justify-center">
        <v-col cols="10" class="pb-0 mb-0">
          <v-form id="formEvent" ref="formEvent" @submit.prevent="createEvent()">
            <v-layout row wrap>
              <v-row class="mt-1">
                <v-col>
                  <v-flex xs12 class="pb-3">
                    <v-textarea auto-grow rows="1" row-height="15" autofocus
                      style="white-space: pre-wrap;"
                      label="Comentários"
                      v-model="caseComment.body"
                      placeholder="Comentários"
                      ref="textAreaComment"
                    ></v-textarea>
                  </v-flex>
                  <v-flex>
                    <form-file-upload :outline="true"
                      ref="customFileUpload"
                      :maximum="10"
                      :limit-file-size="12"
                      @update="setAttachments"
                    />
                  </v-flex>
                </v-col>
                </v-row>
              </v-layout>
          </v-form>
        </v-col>
        <v-col cols="12" class="px-7 mt-4">
          <GenericBtn
              :btn-props="{ block: true, large: true, loading: busy, disabled: busy,
              form: 'formEvent', type: 'submit', color: 'primary' }"
          >Solicitar</GenericBtn>

          <GenericBtn
              :color="'secundary'"
              :on-click="() => ($router.go(-1))"
              :btn-props="{ block: true, large: true, light: true, loading: busy, disabled: busy,
              type: 'submit', color: 'secundary' }"
              class="black--text mt-4"
          >Cancelar</GenericBtn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import { mapMutations, mapState } from "vuex";
import { formrules } from "@/common/formrules";
import ContentVersionService from "../../services/odoo/ContentVersionService";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import CaseService from "../../services/odoo/CaseService";
import ComentsService from "@/services/odoo/ComentsService";
import GenericBtn from "@/components/generic-btn/index"
import moment from "moment";

export default {
  name: 'atendimento-addEvent',
  components: { FormFileUpload, GenericBtn},
  data: () => ({
    id: null,
    defaultErrorObject: { text: "Erro ao inserir", type: "error" },
    attachments: [],
    caseComment: {
      name: moment(new Date()).format("DD/MM/YYYY - HH:mm"),
      res_id: null,
      body: null,
      model: "helpdesk.ticket",
      message_type: "comment",
      email_from: null,
      create_date: null,
      author_id: null,
      attachment_ids: [],
    },
    rules: formrules,
    busy: false,
    dialog: false,
  }),
  computed: {
    ...mapState(["window"])
  },
  created() {
    this._contentVersionService = new ContentVersionService();
    this._caseService = new CaseService();
  },
  beforeMount() {
    this.id = this.$route.params.id
    this._comentsService = new ComentsService();
    this.caseComment.res_id = this.id;
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    setAttachments(attachments) {
      this.attachments = attachments;
    },
    async createEvent() {
      // this.busy = true;
      this.loading(true);
      await this.createCaseEvent(this.id)
          .then(response => {
            this.$router.go(-1);
          })
          .finally(() => (this.loading(false)));
    },
    cancelar() {
      this.$router.go(-1);
    },
    getUserEmail() {
      return this.$auth.user() && this.$auth.user().email ? this.$auth.user().email : null;
    },
    async createCaseEvent(id) {
      let emptyFile = false;
      let emptyFileNames = "";
      const files = this.attachments.map(file => {

        let fileName = file.name.substring(0, file.name.lastIndexOf('.')) || '';

        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames+= file.name + "<br/>"
        }

        let f = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };

        return f;
      });

      if(emptyFile){
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        throw "Empty File"
      }

      this.caseComment.email_from = this.getUserEmail();

      const caseCommentAux = Object.assign({}, this.caseComment);
      if(caseCommentAux.body) {
        caseCommentAux.body = caseCommentAux.body.replaceAll('\n', '<br/>');
      }

      let attachments = {
        entityId: id,
        caseComment: caseCommentAux,
        files: files
      }

      await this._caseService.CreateCaseEvent(attachments).catch(someError => {
        throw someError;
      });
    }
  }
}
</script>
<style scoped>

</style>
